
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux'

import { AuditEntryType, AuditTrailEntry, EventType, ReservationType } from '../../store/pages/diary/types';
import { Gender, MarketingPreference } from '../../store/pages/customer/types';
import AuditLog from '../icons/auditLog'
import { Time } from '../../store/global/types';
import { ActivityFormat } from '../../store/pages/activityFormats/types';
import { Product, ProductPricingMode } from '../../store/pages/products/types';
import { isNullOrEmpty, parseLocalDateTime } from '../../utils/util';
import { DateFormat, TimeFormat } from '../../store/pages/venues/types';
import { MembershipType } from '../../store/pages/memberships/types';

interface ComponentProps {
    logEntry: AuditTrailEntry;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    showIcon?: boolean;
}

interface MappedReduxState {
    activityFormats: ActivityFormat[];
    products: Product[];
    membershipTypes: MembershipType[];
}

type AuditLogEntryProps = MappedReduxState & ComponentProps;

class AuditLogEntry extends React.Component<AuditLogEntryProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { logEntry, timeFormat, dateFormat, showIcon } = this.props;
        const { t } = this.context;
        return <li key={logEntry.id} className={'booking-note-list-item'}>
            <div>{formatLogHeading(logEntry, t)}</div>
            <div className='small'>{formatLogArgs(logEntry, this.props, t)}</div>
            <div className='flex'>
                {showIcon ? <div className='flex-shrink'><AuditLog width={16} height={16} colour='#337AB7' /></div> : null}
                <div className='text-right small flex-stretch'>{`${logEntry.userName} - ${logEntry.time.toShortDateString(dateFormat)} ${logEntry.time.toFullTimeString(timeFormat)}`}</div>
            </div>
        </li>
    }
}

const formatLogHeading = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { auditEntryType, entityText } = logEntry;
    switch (auditEntryType) {
        case AuditEntryType.BookingCreated: return t('AuditEntryType:BookingCreated', { entityText: entityText });
        case AuditEntryType.BillCreated: return t('AuditEntryType:BillCreated', { entityText: entityText });
        case AuditEntryType.BillItemCreated: return t('AuditEntryType:BillItemCreated', { entityText: entityText });
        case AuditEntryType.ReservationCreated: return t('AuditEntryType:ReservationCreated', { entityText: entityText });
        case AuditEntryType.ReservationStartTimeChanged: return t('AuditEntryType:ReservationStartTimeChanged', { entityText: entityText });
        case AuditEntryType.ReservationDurationChanged: return t('AuditEntryType:ReservationDurationChanged', { entityText: entityText });
        case AuditEntryType.ReservationActivityChanged: return t('AuditEntryType:ReservationActivityChanged', { entityText: entityText });
        case AuditEntryType.ReservationMaxParticipantsChanged: return t('AuditEntryType:ReservationMaxParticipantsChanged', { entityText: entityText });
        case AuditEntryType.ReservationTypeChanged: return t('AuditEntryType:ReservationTypeChanged', { entityText: entityText });
        case AuditEntryType.BookingCancelled: return t('AuditEntryType:BookingCancelled', { entityText: entityText });
        case AuditEntryType.BookingReinstated: return t('AuditEntryType:BookingReinstated', { entityText: entityText });
        case AuditEntryType.BookingPublicWebsiteChanged: return t('AuditEntryType:BookingPublicWebsiteChanged', { entityText: entityText });
        case AuditEntryType.ReservationMembershipTypeChanged: return t('AuditEntryType:ReservationMembershipTypeChanged', { entityText: entityText });

        case AuditEntryType.BillItemProductChanged: return t('AuditEntryType:BillItemProductChanged', { entityText: entityText });
        case AuditEntryType.BillItemPriceChanged: return t('AuditEntryType:BillItemPriceChanged', { entityText: entityText });
        case AuditEntryType.BillItemQuantityChanged: return t('AuditEntryType:BillItemQuantityChanged', { entityText: entityText });
        case AuditEntryType.BillItemProductTagChanged: return t('AuditEntryType:BillItemProductTagChanged', { entityText: entityText });
        case AuditEntryType.BillItemReservationChanged: return t('AuditEntryType:BillItemReservationChanged', { entityText: entityText });
        case AuditEntryType.BillItemArchived: return t('AuditEntryType:BillItemArchived', { entityText: entityText });
        case AuditEntryType.BillItemRescheduled: return t('AuditEntryType:BillItemRescheduled', { entityText: entityText });
        case AuditEntryType.BillItemTaxRateChanged: return t('AuditEntryType:BillItemTaxRateChanged', { entityText: entityText });
        case AuditEntryType.BillItemReinstated: return t('AuditEntryType:BillItemReinstated', { entityText: entityText });

        case AuditEntryType.VoucherChanged: return t('AuditEntryType:VoucherRedemptionAmountChanged', { entityText: entityText });
        case AuditEntryType.VoucherExpiryChanged: return t('AuditEntryType:VoucherExpiryChanged', { entityText: entityText });
        case AuditEntryType.VoucherImported: return t('AuditEntryType:VoucherImported', { entityText: entityText });

        case AuditEntryType.CustomerProfileNameChanged: return t('AuditEntryType:CustomerProfileNameChanged', { entityText: entityText });
        case AuditEntryType.CustomerAddressChanged: return t('AuditEntryType:CustomerAddressChanged', { entityText: entityText });
        case AuditEntryType.CustomerContactPreferencesChanged: return t('AuditEntryType:CustomerContactPreferencesChanged', { entityText: entityText });
        case AuditEntryType.CustomerContactDetailsChanged: return t('AuditEntryType:CustomerContactDetailsChanged', { entityText: entityText });
        case AuditEntryType.CustomerEmergencyContactChanged: return t('AuditEntryType:CustomerEmergencyContactChanged', { entityText: entityText });
        case AuditEntryType.CustomerBirthdayChanged: return t('AuditEntryType:CustomerBirthdayChanged', { entityText: entityText });
        case AuditEntryType.CustomerPublicResultsPreferencesChanged: return t('AuditEntryType:CustomerPublicResultsPreferencesChanged', { entityText: entityText });
        case AuditEntryType.CustomerGenderChanged: return t('AuditEntryType:CustomerGenderChanged', { entityText: entityText });

        case AuditEntryType.BillDiscountApplied: return t('AuditEntryType:BillDiscountApplied', { entityText: entityText });
        case AuditEntryType.BillDiscountRemoved: return t('AuditEntryType:BillDiscountRemoved', { entityText: entityText });

        case AuditEntryType.MembershipCreated: return t('AuditEntryType:MembershipCreated', { entityText: entityText });
        case AuditEntryType.MembershipAssignToCustomer: return t('AuditEntryType:MembershipAssignToCustomer', { entityText: entityText });
        case AuditEntryType.MembershipTransferred: return t('AuditEntryType:MembershipTransferred', { entityText: entityText });
        case AuditEntryType.MembershipExpiryChanged: return t('AuditEntryType:MembershipExpiryChanged', { entityText: entityText });

        case AuditEntryType.EventNameChanged: return t('AuditEntryType:EventNameChanged', { entityText: entityText });

        default: return '** Unknown log entry ' + AuditEntryType[auditEntryType] + ' **';
    }
}

const formatLogArgs = (logEntry: AuditTrailEntry, props: AuditLogEntryProps, t: (key: any, params?: any, comment?: string) => string) => {
    const { auditEntryType, entityText } = logEntry;
    switch (auditEntryType) {
        case AuditEntryType.BookingCreated: return '';
        case AuditEntryType.BillCreated: return '';
        case AuditEntryType.BillItemCreated: return formatBillItemCreatedArgs(logEntry, t);
        case AuditEntryType.ReservationCreated: return formatReservationArgs(logEntry, props.timeFormat, props.dateFormat, t);
        case AuditEntryType.ReservationStartTimeChanged: return formatReservationStartTimeChangeArgs(logEntry, props.timeFormat, props.dateFormat, t);
        case AuditEntryType.ReservationDurationChanged: return formatReservationDurationChangeArgs(logEntry, t);
        case AuditEntryType.ReservationActivityChanged: return formatReservationActivityChangedArgs(logEntry, props.activityFormats);
        case AuditEntryType.ReservationMaxParticipantsChanged: return formatReservationMaxParticipantsChangedArgs(logEntry, t);
        case AuditEntryType.ReservationTypeChanged: return formatReservationTypeChangedArgs(logEntry, t);
        case AuditEntryType.BookingCancelled: return '';
        case AuditEntryType.BookingReinstated: return '';
        case AuditEntryType.BookingPublicWebsiteChanged: return formatBookingPublicWebsiteChangedChangedArgs(logEntry, t);
        case AuditEntryType.ReservationMembershipTypeChanged: return formatRestrictedMembershipTypeChangedArgs(logEntry, props.membershipTypes, t);

        case AuditEntryType.BillItemProductChanged: return formatBillItemProductChangedArgs(logEntry, props.products);
        case AuditEntryType.BillItemPriceChanged: return formatPriceChangedArgs(logEntry, t);
        case AuditEntryType.BillItemQuantityChanged: return formatQuantityChangedArgs(logEntry, t);
        case AuditEntryType.BillItemProductTagChanged: return '';
        case AuditEntryType.BillItemReservationChanged: return '';
        case AuditEntryType.BillItemArchived: return '';
        case AuditEntryType.BillItemRescheduled: return '';
        case AuditEntryType.BillItemTaxRateChanged: return formatTaxRateChangedArgs(logEntry, t);
        case AuditEntryType.BillItemReinstated: return '';

        case AuditEntryType.VoucherChanged: return formatVoucherChange(logEntry, props.dateFormat, t);
        case AuditEntryType.VoucherExpiryChanged: return formatVoucherExpiryChange(logEntry, props.dateFormat, t);
        case AuditEntryType.VoucherImported: return formatVoucherImported(logEntry, props.dateFormat, t);

        case AuditEntryType.CustomerProfileNameChanged: return formatCustomerNameChangedArgs(logEntry, t);
        case AuditEntryType.CustomerAddressChanged: return formatAddressChangedArgs(logEntry, t);
        case AuditEntryType.CustomerContactPreferencesChanged: return formatContactPreferencesArgs(logEntry, t);
        case AuditEntryType.CustomerContactDetailsChanged: return formatContactDetailsChangedArgs(logEntry, t);
        case AuditEntryType.CustomerEmergencyContactChanged: return formatEmergencyContactChangedArgs(logEntry, t);
        case AuditEntryType.CustomerBirthdayChanged: return formatDateOfBirthChangedArgs(logEntry, t);
        case AuditEntryType.CustomerPublicResultsPreferencesChanged: return formatPublicResultPreferenceChangedArgs(logEntry, t);
        case AuditEntryType.CustomerGenderChanged: return formatCustomerGenderChangedArgs(logEntry, t);

        case AuditEntryType.BillDiscountApplied: return formatBillDiscountAppliedArgs(logEntry, t);
        case AuditEntryType.BillDiscountRemoved: return formatBillDiscountRemovedArgs(logEntry, t);

        case AuditEntryType.MembershipCreated: return '';
        case AuditEntryType.MembershipAssignToCustomer: return formatMembershipAssignToCustomerArgs(logEntry, t);
        case AuditEntryType.MembershipTransferred: return formatMembershipTransferredArgs(logEntry, t);
        case AuditEntryType.MembershipExpiryChanged: return formatMembershipExpiryChangedArgs(logEntry, props.dateFormat);

        case AuditEntryType.EventNameChanged: return formatEventNameChangedArgs(logEntry);

        default: return '** Unknown log entry ' + AuditEntryType[auditEntryType] + ' **';
    }
}

const formatReservationArgs = (logEntry: AuditTrailEntry, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const startTime = args.length > 0 ? new Date(args[0]) : null;
    const endTime = args.length > 1 ? new Date(args[1]) : null;
    const isMultiDay = startTime && endTime && (endTime > new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 23, 59, 59));

    return `[${formatDateTime(startTime, timeFormat, dateFormat)} - ${isMultiDay ? formatDateTime(endTime, timeFormat, dateFormat) : formatTime(endTime, timeFormat)}]`;
}

const formatReservationStartTimeChangeArgs = (logEntry: AuditTrailEntry, timeFormat: TimeFormat, dateFormat: DateFormat, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const startTime = args.length > 0 ? parseLocalDateTime(args[0]) : null;
    const endTime = args.length > 1 ? parseLocalDateTime(args[1]) : null;

    const startDay = startTime ? (startTime.getFullYear() * 10000) + (startTime.getMonth() * 100) + startTime.getDay() : 0;
    const endDay = endTime ? (endTime.getFullYear() * 10000) + (endTime.getMonth() * 100) + endTime.getDay() : 0;
    const dayChanged = startDay !== endDay;

    return dayChanged ? `[${formatDateTime(startTime, timeFormat, dateFormat)} -> ${formatDateTime(endTime, timeFormat, dateFormat)}]` : `[${formatTime(startTime, timeFormat)} -> ${formatTime(endTime, timeFormat)}]`;
}

const formatReservationDurationChangeArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const originalDuration = args.length > 0 ? Time.parse(args[0]) : null;
    const newDuration = args.length > 1 ? Time.parse(args[1]) : null;

    return `[${originalDuration ? originalDuration.toShortTimeString() : ''} -> ${newDuration ? newDuration.toShortTimeString() : ''}]`;
}

const formatReservationActivityChangedArgs = (logEntry: AuditTrailEntry, activityFormats: ActivityFormat[]) => {
    const { args } = logEntry;

    const originalActivityFormat = args.length > 0 ? activityFormats.find(af => af.id === args[0]) : null;
    const originalActivityFormatVariation = originalActivityFormat && args.length > 1 ? originalActivityFormat.variations.find(v => v.schedule.findIndex(s => s.id === args[1]) >= 0) : null;
    const originalActivityFormatVariationSchedule = originalActivityFormatVariation ? originalActivityFormatVariation.schedule.find(s => s.id === args[1]) : null;
    const activityFormat = args.length > 2 ? activityFormats.find(af => af.id === args[2]) : null;
    const activityFormatVariation = activityFormat && args.length > 3 ? activityFormat.variations.find(v => v.schedule.findIndex(s => s.id === args[3]) >= 0) : null;
    const activityFormatVariationSchedule = activityFormatVariation ? activityFormatVariation.schedule.find(s => s.id === args[3]) : null;

    const from = originalActivityFormat ? `${originalActivityFormat.name}${originalActivityFormatVariationSchedule ? ' (' + originalActivityFormatVariationSchedule.sequence + ')' : ''}` : '';
    const to = activityFormat ? `${activityFormat.name}${activityFormatVariationSchedule ? ' (' + activityFormatVariationSchedule.sequence + ')' : ''}` : '';

    return `[${from} -> ${to}]`;
}

const formatReservationMaxParticipantsChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldQuantity = args.length > 0 ? args[0] : null;
    const newQuantity = args.length > 1 ? args[1] : null;

    return `[${oldQuantity} -> ${newQuantity}]`;
}

const formatReservationTypeChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldTypeVal = args.length > 0 ? parseInt(args[0]) : null;
    const newTypeVal = args.length > 1 ? parseInt(args[1]) : null;

    const oldVal = oldTypeVal && ReservationType[oldTypeVal] ? t(`ReservationType:${ReservationType[oldTypeVal]}`) : null;
    const newVal = newTypeVal && ReservationType[newTypeVal] ? t(`ReservationType:${ReservationType[newTypeVal]}`) : null;

    return `${oldVal} -> ${newVal}`;
}

const formatBookingPublicWebsiteChangedChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldSite = args.length > 0 ? args[0] : null;
    const newSite = args.length > 1 ? args[1] : null;

    return `[${oldSite} -> ${newSite}]`;
}

const formatRestrictedMembershipTypeChangedArgs = (logEntry: AuditTrailEntry, membershipTypes: MembershipType[], t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    
    const originalMembershipType = args.length > 0 && args[0] ? membershipTypes.find(mt => mt.id === args[0]) : null;
    const membershipType = args.length > 1 && args[1] ? membershipTypes.find(mt => mt.id === args[1]) : null;

    const from = originalMembershipType ? originalMembershipType.name : t('Global:none');
    const to = membershipType ? membershipType.name : t('Global:none');

    return `[${from} -> ${to}]`;
}

const formatBillItemCreatedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const quantity = args.length > 1 ? args[1] : null;
    const price = args.length > 3 ? parseFloat(args[3]) : null;

    return `[${quantity} x ${logEntry.entityText} ${(price && !isNaN(price) ? formatCurrencyAmount(price, t) : '')}]`;
}

const formatQuantityChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldQuantity = args.length > 0 ? args[0] : null;
    const newQuantity = args.length > 2 ? args[2] : null;

    if (oldQuantity === newQuantity) return '';
    return `[${oldQuantity} -> ${newQuantity}]`;
}

const formatBillItemProductChangedArgs = (logEntry: AuditTrailEntry, products: Product[]) => {
    const { args } = logEntry;

    const originalProduct = args.length > 0 ? products.find(p => p.id === args[0]) : null;
    const product = args.length > 1 ? products.find(p => p.id === args[1]) : null;

    return `[${originalProduct ? originalProduct.name : ''} -> ${product ? product.name : ''}]`;
}

const formatPriceChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldPrice = args.length > 0 ? parseFloat(args[0]) : null;
    const newPrice = args.length > 2 ? parseFloat(args[2]) : null;
    const priceType = args.length > 3 ? parseInt(args[3]) : null;

    if (oldPrice === newPrice) return '';

    const priceTypeName = priceType ? ` (${t(`ProductPricingMode:${ProductPricingMode[priceType]}`)})` : '';
    return `[${oldPrice !== null && !isNaN(oldPrice) ? formatCurrencyAmount(oldPrice, t) : ''} -> ${newPrice !== null && !isNaN(newPrice) ? formatCurrencyAmount(newPrice, t) : ''} ${priceTypeName}]`;
}

const formatTaxRateChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldRate = args.length > 1 ? parseFloat(args[1]) : null;
    const newRate = args.length > 3 ? parseFloat(args[3]) : null;

    return `[${(oldRate ? oldRate.toFixed(2) : '-')}% -> ${(newRate ? newRate.toFixed(2) : '-')}%]`;
}

const formatCustomerNameChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldName = args.length > 0 ? args[0] : null;
    const oldCompanyName = args.length > 1 ? args[1] : null;
    const newName = args.length > 2 ? args[2] : null;
    const companyName = args.length > 3 ? args[3] : null;

    const changes: string[] = []

    if (oldName !== newName) changes.push(`${oldName} -> ${newName}`);
    if (oldCompanyName !== companyName) changes.push(`${oldCompanyName} -> ${companyName}`);

    return `[${changes.join(',')}]`;
}

const formatAddressChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldAddress = args.length > 0 ? args[0] : null;
    const newAddress = args.length > 1 ? args[1] : null;

    return `[${oldAddress} -> ${newAddress}]`;
}

const formatContactPreferencesArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldMarketingPreference = args.length > 0 ? t(`MarketingPreference:${MarketingPreference[parseInt(args[0])]}`) : 'MarketingPreference:None';
    const oldResultsPreference = args.length > 1 ? t(`MarketingPreference:${MarketingPreference[parseInt(args[1])]}`) : 'MarketingPreference:None';
    const marketingPreference = args.length > 2 ? t(`MarketingPreference:${MarketingPreference[parseInt(args[2])]}`) : 'MarketingPreference:None';
    const resultsPreference = args.length > 3 ? t(`MarketingPreference:${MarketingPreference[parseInt(args[3])]}`) : 'MarketingPreference:None';

    return `[${t('Global:marketing')}: ${t(oldMarketingPreference)} -> ${t(marketingPreference)}, ${t('Global:results')}: ${t(oldResultsPreference)} -> ${t(resultsPreference)}]`;
}

const formatContactDetailsChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldEmail = args.length > 0 ? args[0] : null;
    const oldPhoneNumber = args.length > 1 ? args[1] : null;
    const email = args.length > 2 ? args[2] : null;
    const phoneNumber = args.length > 3 ? args[3] : null;

    return `[${oldEmail} -> ${email}, ${oldPhoneNumber} -> ${phoneNumber}]`;
}

const formatEmergencyContactChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldEmergencyName = args.length > 0 ? args[0] : null;
    const oldEmergencyNumber = args.length > 1 ? args[1] : null;
    const emergencyName = args.length > 2 ? args[2] : null;
    const emergencyNumber = args.length > 3 ? args[3] : null;

    return `[${oldEmergencyName} -> ${emergencyName}, ${oldEmergencyNumber} -> ${emergencyNumber}]`;
}

const formatDateOfBirthChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    //oldBirthDay, oldBirthMonth, oldBirthYear, birthDay, birthMonth, birthYear
    const oldBirthDay = args.length > 0 ? args[0] : null;
    const oldBirthMonth = args.length > 1 ? args[1] : null;
    const oldBirthYear = args.length > 2 ? args[2] : null;
    const birthDay = args.length > 3 ? args[3] : null;
    const birthMonth = args.length > 4 ? args[4] : null;
    const birthYear = args.length > 5 ? args[5] : null;

    return `[${oldBirthYear}-${oldBirthMonth}-${oldBirthDay} -> ${birthYear}-${birthMonth}-${birthDay}]`;
}

const formatPublicResultPreferenceChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldNickname = args.length > 0 ? args[0] : null;
    const oldPublicResultConsent = args.length > 1 ? args[1] : null;
    const nickname = args.length > 2 ? args[2] : null;
    const publicResultsConsent = args.length > 3 ? args[3] : null;

    return `[${oldNickname} -> ${nickname}, ${oldPublicResultConsent} -> ${publicResultsConsent}]`;
}

const formatCustomerGenderChangedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldgender = args.length > 0 ? parseInt(args[0]) : Gender.NotProvided;
    const gender = args.length > 1 ? parseInt(args[1]) : Gender.NotProvided;

    return `[${t(`Gender:${Gender[oldgender]}`)} -> ${t(`Gender:${Gender[gender]}`)}]`;
}

const formatVoucherChange = (logEntry: AuditTrailEntry, dateFormat: DateFormat, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldRedemptionAmount = args.length > 0 && args[0] ? parseFloat(args[0]) : null;
    const redemptionAmount = args.length > 2 && args[2] ? parseFloat(args[2]) : null;

    const formattedOldRedemptionAmount = oldRedemptionAmount && !isNaN(oldRedemptionAmount) ? `${t('Global:currencySymbol')}${oldRedemptionAmount.toFixed(2)}` : '';
    const formattedoldRedemptionAmount = redemptionAmount && !isNaN(redemptionAmount) ? `${t('Global:currencySymbol')}${redemptionAmount.toFixed(2)}` : '';

    return `[${formattedOldRedemptionAmount} -> ${formattedoldRedemptionAmount}]`;
}

const formatVoucherExpiryChange = (logEntry: AuditTrailEntry, dateFormat: DateFormat, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const oldExpiry = args.length > 0 && args[0] ? new Date(args[0]) : null;
    const newExpiry = args.length > 1 && args[1] ? new Date(args[1]) : null;
    return `[${oldExpiry ? oldExpiry.toAbbrDateString(dateFormat, t) : ''} -> ${newExpiry ? newExpiry.toAbbrDateString(dateFormat, t) : ''}]`;
}

const formatVoucherImported = (logEntry: AuditTrailEntry, dateFormat: DateFormat, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const originalAmount = args.length > 0 && args[0] ? parseFloat(args[0]) : null;
    const formattedAmount = originalAmount && !isNaN(originalAmount) ? `${t('Global:currencySymbol')}${originalAmount.toFixed(2)}` : '';
    const balance = args.length > 1 && args[1] ? parseFloat(args[1]) : null;
    const formattedBalance = balance && !isNaN(balance) ? `${t('Global:currencySymbol')}${balance.toFixed(2)}` : '';
    const exipry = args.length > 2 && args[2] ? new Date(args[2]) : null;
    const formattedExpiry = exipry ? exipry.toAbbrDateString(dateFormat, t) : '';

    return `${formattedAmount}, ${formattedBalance}, ${formattedExpiry}`;
}
 
const formatBillDiscountAppliedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const discountName = args.length > 0 && args[0] ? args[0] : null;
    const discountAmount = args.length > 1&& args[1] ? parseFloat(args[1]) : null;
    const discountedAmount = args.length > 2 && args[2] ? parseFloat(args[2]) : null;
    const discountedPercentage = args.length > 3 && args[3] ? parseFloat(args[3]) : null;

    const discAmountTxt = discountAmount && !isNaN(discountAmount) ? `${t('Global:currencySymbol')}${discountAmount.toFixed(2)}` : '';
    const discountedAmountTxt = discountedAmount && !isNaN(discountedAmount) ? `${t('Global:currencySymbol')}${discountedAmount.toFixed(2)}` : '';

    const discInfo = discountedPercentage ? `${discountedPercentage.toFixed(0)}% ${t('Global:of')} ${discountedAmountTxt}` : discountedAmountTxt;

    return `[${discountName} ${discAmountTxt} (${discInfo})]`;
}

const formatBillDiscountRemovedArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const discountName = args.length > 0 && args[0] ? args[0] : null;

    return `[${discountName}]`;
}

const formatMembershipAssignToCustomerArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const customerName = args.length > 1 && args[1] ? args[1] : '';

    return `[${customerName}]`;
}

const formatMembershipTransferredArgs = (logEntry: AuditTrailEntry, t: (key: any, params?: any, comment?: string) => string) => {
    const { args } = logEntry;
    const fromCustomerName = args.length > 1 && args[1] ? args[1] : '';
    const toCustomerName = args.length > 3 && args[3] ? args[3] : '';

    return `[${fromCustomerName} -> ${toCustomerName}]`;
}

const formatMembershipExpiryChangedArgs = (logEntry: AuditTrailEntry, dateFormat: DateFormat) => {
    const { args } = logEntry;
    const fromDateVal = args.length > 0 && args[0] ? args[0] : '';
    const toDateVal = args.length > 1 && args[1] ? args[1] : '';

    const fromDate = !isNullOrEmpty(fromDateVal) ? new Date(fromDateVal) : null;
    const toDate = !isNullOrEmpty(toDateVal) ? new Date(toDateVal) : null;

    return `[${formatDate(fromDate, dateFormat)} -> ${formatDate(toDate, dateFormat)}]`;
}

const formatEventNameChangedArgs = (logEntry: AuditTrailEntry) => {
    const { args } = logEntry;
    const fromName = args.length > 0 && args[0] ? args[0] : '';
    const toName = args.length > 1 && args[1] ? args[1] : '';

    return `[${fromName} -> ${toName}]`;
}

const formatCurrencyAmount = (amount: number, t: (key: any, params?: any, comment?: string) => string) => `${t('Global:currencySymbol')}${amount.toFixed(2)}`;

const formatDate = (date: Date | null, dateFormat: DateFormat) => {
    if (!date) return '';
    return date.toShortDateString(dateFormat);
}

const formatDateTime = (date: Date | null, timeFormat: TimeFormat, dateFormat: DateFormat) => {
    if (!date) return '';
    return `${date.toShortDateString(dateFormat)} ${date.toShortTimeString(timeFormat)}`;
}

const formatTime = (date: Date | null, timeFormat: TimeFormat) => {
    if (!date) return '';
    return date.toShortTimeString(timeFormat);
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        activityFormats: state.activityFormats.activityFormats,
        products: state.products.products,
        membershipTypes: state.memberships.membershipTypes
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({});

// Casting to prevent error where used in index.ts that isBusy is mandatory, since it is being provided by Redux.
export default connect(mapStateToProps, mapDispatchToProps)(AuditLogEntry);

