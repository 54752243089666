
import 'quill/dist/quill.snow.css';
import '../../../css/reactdatetime.css';
import '../../../css/quill.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';
import { Venue, isOpen, DayOfWeek, TimeFormat, DateFormat } from '../../../store/pages/venues/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { ApplicationState } from '../../../store';
import { Time, ValidationError } from '../../../store/global/types';
import * as api from '../../../store/apiClient';
import * as CultureActions from '../../../store/global/cultures/actions';
import * as CurrencyActions from '../../../store/global/currencies/actions';
import * as TimeZoneActions from '../../../store/global/timeZones/actions';
import * as VenueActions from '../../../store/pages/venues/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import {allCountries, ukCountryId} from '../../../store/global/countries';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { Culture } from '../../../store/global/cultures/types';
import { Currency } from '../../../store/global/currencies/types';
import { TimeZone } from '../../../store/global/timeZones/types';
import { ImageFile } from '../../../utils/images';
import { stringComparer } from '../../../utils/comparers';

interface LocalProps {
    isNew: boolean;
    venue: Venue | null;
}

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    isLoadingCultures: boolean;
    cultures: Culture[];
    currencies: Currency[];
    isLoadingCurrencies: boolean;
    timeZones: TimeZone[];
    isLoadingTimeZones: boolean;
}
interface Actions {
    saveVenue: (isNew: boolean, venueId: string | null, venue: Venue, lightLogoImg: File | null, darkLogoImg: File | null) => void;
    loadCultures: () => void;
    loadCurrencies: () => void;
    loadTimeZones: () => void;
    closeModal: () => void;
}

type VenueFormProps = LocalProps & MappedReduxState & Actions;

interface VenueFormState {
    name: ct.FormValue<string>;
    addressLine1: ct.FormValue<string>;
    addressLine2: ct.FormValue<string>;
    addressLine3: ct.FormValue<string>;
    addressLine4: ct.FormValue<string>;
    town: ct.FormValue<string>;
    county: ct.FormValue<string>;
    countryId: ct.FormValue<number>;
    postalCode: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    sendEmailsFromAddress: ct.FormValue<string>;
    sendEmailsFromName: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
    websiteAddress: ct.FormValue<string>;
    salesTaxNumber: ct.FormValue<string>;
    timeZoneId: ct.FormValue<number>;
    culture: ct.FormValue<string>;
    currency: ct.FormValue<number>;
    firstDayOfWeek: ct.FormValue<DayOfWeek>;
    mondayClosed: boolean;
    mondayOpenTime: ct.FormValue<Time>;
    mondayCloseTime: ct.FormValue<Time>;
    tuesdayClosed: boolean;
    tuesdayOpenTime: ct.FormValue<Time>;
    tuesdayCloseTime: ct.FormValue<Time>;
    wednesdayClosed: boolean;
    wednesdayOpenTime: ct.FormValue<Time>;
    wednesdayCloseTime: ct.FormValue<Time>;
    thursdayClosed: boolean;
    thursdayOpenTime: ct.FormValue<Time>;
    thursdayCloseTime: ct.FormValue<Time>;
    fridayClosed: boolean;
    fridayOpenTime: ct.FormValue<Time>;
    fridayCloseTime: ct.FormValue<Time>;
    saturdayClosed: boolean;
    saturdayOpenTime: ct.FormValue<Time>;
    saturdayCloseTime: ct.FormValue<Time>;
    sundayClosed: boolean;
    sundayOpenTime: ct.FormValue<Time>;
    sundayCloseTime: ct.FormValue<Time>;
    minAgeWarningThreshold: ct.FormValue<number | null>;
    minAgeForSelfRegistration: ct.FormValue<number | null>;
    minAge: ct.FormValue<number | null>;
    webBookingExpiry: ct.FormValue<number>;
    webBookingFailedPaymentTimeout: ct.FormValue<number | null>;
    webBookingIncompletePaymentTimeout: ct.FormValue<number | null>;
    archived: ct.FormValue<boolean>;
    lightLogoImageId: string | null;
    lightLogoImage: ImageFile | null;
    darkLogoImageId: string | null;
    darkLogoImage: ImageFile | null;
    webBookingMinTimeFromEventStart: ct.FormValue<Time>;
    requireLatestTerms: ct.FormValue<boolean>;
    timeFormat: ct.FormValue<TimeFormat>;
    dateFormat: ct.FormValue<DateFormat>;
}

class VenueForm extends React.Component<VenueFormProps, VenueFormState> {

    constructor(props: VenueFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: VenueFormProps): VenueFormState {

        const { isNew, venue } = props;

        if (isNew || !venue) {
            return this.buildEmptyState();
        } else {
            return {
                name: this.validateName(venue.name),
                addressLine1: this.validateAddressLine1(venue.addressLine1),
                addressLine2: this.validateAddressLine2(venue.addressLine2),
                addressLine3: this.validateAddressLine3(venue.addressLine3),
                addressLine4: this.validateAddressLine4(venue.addressLine4),
                town: this.validateTown(venue.town),
                county: this.validateCounty(venue.county),
                countryId: this.validateCountry(venue.countryId),
                postalCode: this.validatePostalCode(venue.postalCode),
                emailAddress: this.validateEmailAddress(venue.emailAddress),
                sendEmailsFromAddress: this.validateSendEmailsFromAddress(venue.sendEmailsFromAddress),
                sendEmailsFromName: this.validateSendEmailsFromName(venue.sendEmailsFromName),
                phoneNumber: this.validatePhoneNumber(venue.phoneNumber),
                websiteAddress: this.validateWebsiteAddress(venue.websiteAddress),
                salesTaxNumber: this.validateSalesTaxNumber(venue.salesTaxNumber),
                culture: this.validateCulture(venue.culture),
                timeZoneId: this.validateTimeZone(venue.timeZoneId),
                currency: this.validateCurrency(venue.currencyId),
                firstDayOfWeek: this.validateFirstDayOfWeek(venue.firstDayOfWeek),
                mondayClosed: !isOpen(venue.mondayOpenTime, venue.mondayCloseTime),
                mondayOpenTime: this.validateMondayOpenTime(venue.mondayOpenTime),
                mondayCloseTime: this.validateMondayCloseTime(venue.mondayCloseTime),
                tuesdayClosed: !isOpen(venue.tuesdayOpenTime, venue.tuesdayCloseTime),
                tuesdayOpenTime: this.validateTuesdayOpenTime(venue.tuesdayOpenTime),
                tuesdayCloseTime: this.validateTuesdayCloseTime(venue.tuesdayCloseTime),
                wednesdayClosed: !isOpen(venue.wednesdayOpenTime, venue.wednesdayCloseTime),
                wednesdayOpenTime: this.validateWednesdayOpenTime(venue.wednesdayOpenTime),
                wednesdayCloseTime: this.validateWednesdayCloseTime(venue.wednesdayCloseTime),
                thursdayClosed: !isOpen(venue.thursdayOpenTime, venue.thursdayCloseTime),
                thursdayOpenTime: this.validateThursdayOpenTime(venue.thursdayOpenTime),
                thursdayCloseTime: this.validateThursdayCloseTime(venue.thursdayCloseTime),
                fridayClosed: !isOpen(venue.fridayOpenTime, venue.fridayCloseTime),
                fridayOpenTime: this.validateFridayOpenTime(venue.fridayOpenTime),
                fridayCloseTime: this.validateFridayCloseTime(venue.fridayCloseTime),
                saturdayClosed: !isOpen(venue.saturdayOpenTime, venue.saturdayCloseTime),
                saturdayOpenTime: this.validateSaturdayOpenTime(venue.saturdayOpenTime),
                saturdayCloseTime: this.validateSaturdayCloseTime(venue.saturdayCloseTime),
                sundayClosed: !isOpen(venue.sundayOpenTime, venue.sundayCloseTime),
                sundayOpenTime: this.validateSundayOpenTime(venue.sundayOpenTime),
                sundayCloseTime: this.validateSundayCloseTime(venue.sundayCloseTime),
                minAgeWarningThreshold: this.validateMinAgeWarningThreshold(venue.minAgeWarningThreshold),
                minAgeForSelfRegistration: this.validateMinAgeForSelfRegistration(venue.minAgeForSelfRegistration),
                minAge: this.validateMinAge(venue.minAge),
                webBookingExpiry: this.validateWebBookingExpiry(venue.webBookingExpiry),
                webBookingFailedPaymentTimeout: this.validateWebBookingFailedPaymentTimeout(venue.webBookingFailedPaymentTimeout),
                webBookingIncompletePaymentTimeout: this.validateWebBookingIncompletePaymentTimeout(venue.webBookingIncompletePaymentTimeout),
                archived: this.validateArchived(venue.archived),
                lightLogoImageId: venue.lightLogoImageId,
                lightLogoImage: null,
                darkLogoImageId: venue.darkLogoImageId,
                darkLogoImage: null,
                webBookingMinTimeFromEventStart: this.validateWebBookingMinTimeFromEventStart(venue.webBookingMinTimeFromEventStart),
                requireLatestTerms: this.validateRequireLatestTerms(venue.requireLatestTerms),
                timeFormat: this.validateTimeFormat(venue.timeFormat),
                dateFormat: this.validateDateFormat(venue.dateFormat)
            };
        }
    }

    buildEmptyState = () => {
        const { venue } = this.props;
        return ({
            name: this.validateName(''),
            addressLine1: this.validateAddressLine1(''),
            addressLine2: this.validateAddressLine2(''),
            addressLine3: this.validateAddressLine3(''),
            addressLine4: this.validateAddressLine4(''),
            town: this.validateTown(''),
            county: this.validateCounty(''),
            countryId: this.validateCountry(venue ? venue.countryId : ukCountryId),
            postalCode: this.validatePostalCode(''),
            emailAddress: this.validateEmailAddress(''),
            sendEmailsFromAddress: this.validateSendEmailsFromAddress(''),
            sendEmailsFromName: this.validateSendEmailsFromName(''),
            phoneNumber: this.validatePhoneNumber(''),
            websiteAddress: this.validateWebsiteAddress(''),
            salesTaxNumber: this.validateSalesTaxNumber(''),
            culture: this.validateCulture('en-GB'),
            timeZoneId: this.validateTimeZone(39),
            currency: this.validateCurrency(1),
            firstDayOfWeek: this.validateFirstDayOfWeek(DayOfWeek.Monday),
            mondayClosed: false,
            mondayOpenTime: this.validateMondayOpenTime(new Time(9, 0, 0)),
            mondayCloseTime: this.validateMondayCloseTime(new Time(18, 0, 0)),
            tuesdayClosed: false,
            tuesdayOpenTime: this.validateTuesdayOpenTime(new Time(9, 0, 0)),
            tuesdayCloseTime: this.validateTuesdayCloseTime(new Time(18, 0, 0)),
            wednesdayClosed: false,
            wednesdayOpenTime: this.validateWednesdayOpenTime(new Time(9, 0, 0)),
            wednesdayCloseTime: this.validateWednesdayCloseTime(new Time(18, 0, 0)),
            thursdayClosed: false,
            thursdayOpenTime: this.validateThursdayOpenTime(new Time(9, 0, 0)),
            thursdayCloseTime: this.validateThursdayCloseTime(new Time(18, 0, 0)),
            fridayClosed: false,
            fridayOpenTime: this.validateFridayOpenTime(new Time(9, 0, 0)),
            fridayCloseTime: this.validateFridayCloseTime(new Time(18, 0, 0)),
            saturdayClosed: false,
            saturdayOpenTime: this.validateSaturdayOpenTime(new Time(9, 0, 0)),
            saturdayCloseTime: this.validateSaturdayCloseTime(new Time(18, 0, 0)),
            sundayClosed: false,
            sundayOpenTime: this.validateSundayOpenTime(new Time(9, 0, 0)),
            sundayCloseTime: this.validateSundayCloseTime(new Time(18, 0, 0)),
            minAgeWarningThreshold: this.validateMinAgeWarningThreshold(null),
            minAgeForSelfRegistration: this.validateMinAgeForSelfRegistration(null),
            minAge: this.validateMinAge(null),
            webBookingExpiry: this.validateWebBookingExpiry(15),
            webBookingFailedPaymentTimeout: this.validateWebBookingFailedPaymentTimeout(null),
            webBookingIncompletePaymentTimeout: this.validateWebBookingIncompletePaymentTimeout(null),
            archived: this.validateArchived(false),
            lightLogoImageId: null,
            lightLogoImage: null,
            darkLogoImageId: null,
            darkLogoImage: null,
            webBookingMinTimeFromEventStart: this.validateWebBookingMinTimeFromEventStart(new Time(0, 30, 0)),
            requireLatestTerms: this.validateRequireLatestTerms(false),
            timeFormat: this.validateTimeFormat(TimeFormat.TwentyFourHour),
            dateFormat: this.validateDateFormat(DateFormat.DMY)
        });
    }

    componentDidMount() {
        const { isLoadingCultures, cultures, loadCultures, timeZones, isLoadingTimeZones, loadTimeZones } = this.props;
        if ((!cultures || cultures.length === 0) && !isLoadingCultures) {
            loadCultures();
        }
        if ((!timeZones || timeZones.length === 0) && !isLoadingTimeZones) {
            loadTimeZones();
        }
    }

    componentDidUpdate(prevProps: VenueFormProps) {
        const { venue: prevVenue, saveComplete: prevSaveComplete } = prevProps;
        const { venue, saveComplete } = this.props;

        // Only update state is venue has changed
        if ((!prevVenue && venue) || (prevVenue && !venue) || (prevVenue && venue && prevVenue.id !== venue.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }

        // need to update validations with validation errors if they have changed
    }

    private saveVenue = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const venueId = this.props.isNew || !this.props.venue ? null : this.props.venue.id;
            this.props.saveVenue(this.props.isNew,
                venueId,
                {
                    id: venueId || '',
                    name: this.state.name.value,
                    addressLine1: this.state.addressLine1.value,
                    addressLine2: this.state.addressLine2.value,
                    addressLine3: this.state.addressLine3.value,
                    addressLine4: this.state.addressLine4.value,
                    town: this.state.town.value,
                    county: this.state.county.value,
                    countryId: this.state.countryId.value,
                    postalCode: this.state.postalCode.value,
                    emailAddress: this.state.emailAddress.value,
                    sendEmailsFromAddress: this.state.sendEmailsFromAddress.value,
                    sendEmailsFromName: this.state.sendEmailsFromName.value,
                    phoneNumber: this.state.phoneNumber.value,
                    websiteAddress: this.state.websiteAddress.value,
                    salesTaxNumber: this.state.salesTaxNumber.value,
                    culture: this.state.culture.value,
                    currencyId: this.state.currency.value,
                    firstDayOfWeek: this.state.firstDayOfWeek.value,
                    currencySymbol: '',
                    timeZoneId: this.state.timeZoneId.value,
                    mondayOpenTime: this.state.mondayClosed ? Time.zero() : this.state.mondayOpenTime.value,
                    mondayCloseTime: this.state.mondayClosed ? Time.zero() : this.state.mondayCloseTime.value,
                    tuesdayOpenTime: this.state.tuesdayClosed ? Time.zero() : this.state.tuesdayOpenTime.value,
                    tuesdayCloseTime: this.state.tuesdayClosed ? Time.zero() : this.state.tuesdayCloseTime.value,
                    wednesdayOpenTime: this.state.wednesdayClosed ? Time.zero() : this.state.wednesdayOpenTime.value,
                    wednesdayCloseTime: this.state.wednesdayClosed ? Time.zero() : this.state.wednesdayCloseTime.value,
                    thursdayOpenTime: this.state.thursdayClosed ? Time.zero() : this.state.thursdayOpenTime.value,
                    thursdayCloseTime: this.state.thursdayClosed ? Time.zero() : this.state.thursdayCloseTime.value,
                    fridayOpenTime: this.state.fridayClosed ? Time.zero() : this.state.fridayOpenTime.value,
                    fridayCloseTime: this.state.fridayClosed ? Time.zero() : this.state.fridayCloseTime.value,
                    saturdayOpenTime: this.state.saturdayClosed ? Time.zero() : this.state.saturdayOpenTime.value,
                    saturdayCloseTime: this.state.saturdayClosed ? Time.zero() : this.state.saturdayCloseTime.value,
                    sundayOpenTime: this.state.sundayClosed ? Time.zero() : this.state.sundayOpenTime.value,
                    sundayCloseTime: this.state.sundayClosed ? Time.zero() : this.state.sundayCloseTime.value,
                    archived: this.state.archived.value,
                    registrationConfigured: !this.props.venue ? false : this.props.venue.registrationConfigured,
                    minAgeWarningThreshold: this.state.minAgeWarningThreshold.value,
                    minAgeForSelfRegistration: this.state.minAgeForSelfRegistration.value,
                    minAge: this.state.minAge.value,
                    publicWebsites: [],
                    webBookingExpiry: this.state.webBookingExpiry.value,
                    webBookingFailedPaymentTimeout: this.state.webBookingFailedPaymentTimeout.value,
                    webBookingIncompletePaymentTimeout: this.state.webBookingIncompletePaymentTimeout.value,
                    registrationKiosks: [],
                    lightLogoImageId: null,
                    lightLogoImageUrl: null,
                    darkLogoImageId: null,
                    darkLogoImageUrl: null,
                    webBookingMinTimeFromEventStart: this.state.webBookingMinTimeFromEventStart.value,
                    lastUpdated: 0,
                    integrationTokens: [],
                    hasResources: false,
                    requireLatestTerms: this.state.requireLatestTerms.value,
                    timeFormat: this.state.timeFormat.value,
                    dateFormat: this.state.dateFormat.value,
                    enableMultipleCategoriesPerCustomer: false
                },
                this.state.lightLogoImage ? this.state.lightLogoImage.file : null,
                this.state.darkLogoImage ? this.state.darkLogoImage.file : null
            );
        }
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateAddressLine1 = (val: string) => v.validate(val, 'addressLine1', [v.required], this.props.validationErrors);
    validateAddressLine2 = (val: string) => v.validate(val, 'addressLine2', [], this.props.validationErrors);
    validateAddressLine3 = (val: string) => v.validate(val, 'addressLine3', [], this.props.validationErrors);
    validateAddressLine4 = (val: string) => v.validate(val, 'addressLine4', [], this.props.validationErrors);
    validateTown = (val: string) => v.validate(val, 'town', [v.required], this.props.validationErrors);
    validateCounty = (val: string) => v.validate(val, 'county', [v.required], this.props.validationErrors);
    validateCountry = (val: number) => v.validate(val, 'country', [v.numeric], this.props.validationErrors);
    validatePostalCode = (val: string) => v.validate(val, 'postalCode', [v.required], this.props.validationErrors);
    validateEmailAddress = (val: string) => v.validate(val, 'EmailAddress', [v.required], this.props.validationErrors);
    validateSendEmailsFromAddress = (val: string) => v.validate(val, 'SendEmailsFromAddress', [v.required], this.props.validationErrors);
    validateSendEmailsFromName = (val: string) => v.validate(val, 'sendEmailsFromName', [], this.props.validationErrors);
    validatePhoneNumber = (val: string) => v.validate(val, 'phoneNumber', [], this.props.validationErrors);
    validateWebsiteAddress = (val: string) => v.validate(val, 'websiteAddress', [], this.props.validationErrors);
    validateSalesTaxNumber = (val: string) => v.validate(val, 'salesTaxNumber', [], this.props.validationErrors);
    validateTimeZone = (val: number) => v.validate(val, 'timeZone', [v.numeric, v.required], this.props.validationErrors);
    validateCulture = (val: string) => v.validate(val, 'culture', [v.required], this.props.validationErrors);
    validateCurrency = (val: number) => v.validate(val, 'currency', [v.numeric], this.props.validationErrors);
    validateFirstDayOfWeek = (val: DayOfWeek) => v.validate(val, 'firstDayOfWeek', [], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateWebBookingExpiry = (val: number) => v.validate(val, 'webBookingExpiry', [v.numeric], this.props.validationErrors);
    validateWebBookingFailedPaymentTimeout = (val: number | null) => v.validate(val, 'webBookingFailedPaymentTimeout', [], this.props.validationErrors);
    validateWebBookingIncompletePaymentTimeout = (val: number | null) => v.validate(val, 'webBookingIncompletePaymentTimeout', [], this.props.validationErrors);

    validateMondayOpenTime = (val: Time) => v.validate(val, 'mondayOpenTime', [v.required], this.props.validationErrors);
    validateMondayCloseTime = (val: Time) => v.validate(val, 'mondayCloseTime', [v.required], this.props.validationErrors);
    validateTuesdayOpenTime = (val: Time) => v.validate(val, 'tuesdayOpenTime', [v.required], this.props.validationErrors);
    validateTuesdayCloseTime = (val: Time) => v.validate(val, 'tuesdayCloseTime', [v.required], this.props.validationErrors);
    validateWednesdayOpenTime = (val: Time) => v.validate(val, 'wednesdayOpenTime', [v.required], this.props.validationErrors);
    validateWednesdayCloseTime = (val: Time) => v.validate(val, 'wednesdayCloseTime', [v.required], this.props.validationErrors);
    validateThursdayOpenTime = (val: Time) => v.validate(val, 'thursdayOpenTime', [v.required], this.props.validationErrors);
    validateThursdayCloseTime = (val: Time) => v.validate(val, 'thursdayCloseTime', [v.required], this.props.validationErrors);
    validateFridayOpenTime = (val: Time) => v.validate(val, 'fridayOpenTime', [v.required], this.props.validationErrors);
    validateFridayCloseTime = (val: Time) => v.validate(val, 'fridayCloseTime', [v.required], this.props.validationErrors);
    validateSaturdayOpenTime = (val: Time) => v.validate(val, 'saturdayOpenTime', [v.required], this.props.validationErrors);
    validateSaturdayCloseTime = (val: Time) => v.validate(val, 'saturdayCloseTime', [v.required], this.props.validationErrors);
    validateSundayOpenTime = (val: Time) => v.validate(val, 'sundayOpenTime', [v.required], this.props.validationErrors);
    validateSundayCloseTime = (val: Time) => v.validate(val, 'sundayCloseTime', [v.required], this.props.validationErrors);
    validateMinAgeWarningThreshold = (val: number | null) => v.validate(val, 'minAgeWarningThreshold', [], this.props.validationErrors);
    validateMinAgeForSelfRegistration = (val: number | null) => v.validate(val, 'minAgeForSelfRegistration', [], this.props.validationErrors);
    validateMinAge = (val: number | null) => v.validate(val, 'minAge', [], this.props.validationErrors);
    validateWebBookingMinTimeFromEventStart = (val: Time) => v.validate(val, 'webBookingMinTimeFromEventStart', [v.required], this.props.validationErrors);
    validateRequireLatestTerms = (val: boolean) => v.validate(val, 'requireLatestTerms', [], []);
    validateTimeFormat = (val: TimeFormat) => v.validate(val, 'timeFormat', [], []);
    validateDateFormat = (val: DateFormat) => v.validate(val, 'dateFormat', [], []);

    onLightImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ lightLogoImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    onDarkImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ darkLogoImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    render() {
        const { t } = this.context;
        let message: any;

        if (this.props.saveError) {
             message = <ApiError error={this.props.saveError} />;
        } else if (this.props.saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const { cultures, currencies, timeZones } = this.props;
        const { name, addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, emailAddress,
            phoneNumber, websiteAddress, salesTaxNumber, culture, archived, minAgeWarningThreshold, minAgeForSelfRegistration, minAge,
            currency, firstDayOfWeek, timeZoneId, sendEmailsFromAddress, sendEmailsFromName, webBookingExpiry, webBookingFailedPaymentTimeout,
            webBookingIncompletePaymentTimeout, requireLatestTerms, timeFormat, dateFormat } = this.state;

        let lightPreviewImg: JSX.Element | null = null;
        const lightImageUrl = this.state.lightLogoImage ? this.state.lightLogoImage.preview : this.state.lightLogoImageId !== null ? api.getClientImageThunbUrl(this.state.lightLogoImageId, 200) : null;
        if (lightImageUrl) {
            lightPreviewImg = <img src={lightImageUrl} className='file-preview' alt='light logo'></img>;
        }

        let darkPreviewImg: JSX.Element | null = null;
        const darkImageUrl = this.state.darkLogoImage ? this.state.darkLogoImage.preview : this.state.darkLogoImageId !== null ? api.getClientImageThunbUrl(this.state.darkLogoImageId, 200) : null;
        if (darkImageUrl) {
            darkPreviewImg = <img src={darkImageUrl} className='file-preview' alt='dark logo'></img>;
        }

        const timeStyle = ({margin: '0 5px'})

        var firstDayOfWeekOptions = Object.keys(DayOfWeek).filter(key => typeof DayOfWeek[key as any] === 'number').map(key => ({ key: DayOfWeek[key as any].toString(), name: t(`DayOfWeek:${key}`) }));
        var timeFormatOptions = Object.keys(TimeFormat).filter(key => typeof TimeFormat[key as any] === 'number').map(key => ({ key: TimeFormat[key as any].toString(), name: t(`TimeFormat:${key}`) }));
        var dateFormatOptions = Object.keys(DateFormat).filter(key => typeof DateFormat[key as any] === 'number').map(key => ({ key: DateFormat[key as any].toString(), name: t(`DateFormat:${key}`) }));

        return <div className='venueForm'>
            <h1 className='venue_title'>{this.props.isNew ? t('VenueForm:addVenue') : name.value}</h1>

            <form className='data-form' onSubmit={this.saveVenue} autoComplete='off'>
                <ct.TextBox id='name' labelKey='VenueForm:name' placeholderKey='VenueForm:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <span>{t('VenueForm:lightLogo')}</span>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onLightImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {lightPreviewImg}
                </div>

                <span>{t('VenueForm:darkLogo')}</span>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onDarkImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {darkPreviewImg}
                </div>


                <ct.TextBox id='addressLine1' labelKey='Global:addressLine1' placeholderKey='Global:addressLine1' value={addressLine1} callback={val => this.setState({ addressLine1: this.validateAddressLine1(val) })} />
                <ct.TextBox id='addressLine2' labelKey='Global:addressLine2' placeholderKey='Global:addressLine2' value={addressLine2} callback={val => this.setState({ addressLine2: this.validateAddressLine2(val) })} />
                <ct.TextBox id='addressLine3' labelKey='Global:addressLine3' placeholderKey='Global:addressLine3' value={addressLine3} callback={val => this.setState({ addressLine3: this.validateAddressLine3(val) })} />
                <ct.TextBox id='addressLine4' labelKey='Global:addressLine4' placeholderKey='Global:addressLine4' value={addressLine4} callback={val => this.setState({ addressLine4: this.validateAddressLine4(val) })} />
                <ct.TextBox id='town' labelKey='Global:town' placeholderKey='Global:town' value={town} callback={val => this.setState({ town: this.validateTown(val) })} />
                <ct.TextBox id='county' labelKey='Global:county' placeholderKey='Global:county' value={county} callback={val => this.setState({ county: this.validateCounty(val) })} />
                <ct.Country id='country' labelKey='Global:country' placeholderKey='Global:country' value={countryId} callback={val => this.setState({ countryId: this.validateCountry(val) })} countries={allCountries} />
                <ct.TextBox id='postalCode' labelKey='Global:postalCode' placeholderKey='Global:postalCode' value={postalCode} callback={val => this.setState({ postalCode: this.validatePostalCode(val) })} />
                <ct.Email id='emailAddress' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setState({ emailAddress: this.validateEmailAddress(val) })} />
                <ct.Email id='sendEmailsFromAddress' labelKey='Global:sendEmailsFromAddress' placeholderKey='Global:sendEmailsFromAddress' value={sendEmailsFromAddress} callback={val => this.setState({ sendEmailsFromAddress: this.validateSendEmailsFromAddress(val) })} />
                <ct.Email id='sendEmailsFromName' labelKey='Global:sendEmailsFromName' placeholderKey='Global:sendEmailsFromName' value={sendEmailsFromName} callback={val => this.setState({ sendEmailsFromName: this.validateSendEmailsFromName(val) })} />
                <ct.PhoneNumber id='phoneNumber' labelKey='Global:phoneNumber' placeholderKey='Global:phoneNumber' value={phoneNumber} callback={val => this.setState({ phoneNumber: this.validatePhoneNumber(val) })} />
                <ct.TextBox id='websiteAddress' labelKey='Global:websiteAddress' placeholderKey='Global:websiteAddress' value={websiteAddress} callback={val => this.setState({ websiteAddress: this.validateWebsiteAddress(val) })} />
                <ct.TextBox id='salesTaxNumber' labelKey='VenueForm:salesTaxNumber' placeholderKey='VenueForm:salesTaxNumber' value={salesTaxNumber} callback={val => this.setState({ salesTaxNumber: this.validateSalesTaxNumber(val) })} />

                <ct.NumberBox id='webBookingExpiry' labelKey='VenueForm:webBookingExpiry' placeholderKey='VenueForm:webBookingExpiry' value={webBookingExpiry} callback={val => this.setState({ webBookingExpiry: this.validateWebBookingExpiry(val || 15) })} min='5' />
                <ct.NumberBox id='webBookingFailedPaymentTimeout' labelKey='VenueForm:webBookingFailedPaymentTimeout' placeholderKey='VenueForm:noExpiry' hintKey='VenueForm:webBookingFailedPaymentTimeoutHint' value={webBookingFailedPaymentTimeout} callback={val => this.setState({ webBookingFailedPaymentTimeout: this.validateWebBookingFailedPaymentTimeout(val) })} />
                <ct.NumberBox id='webBookingIncompletePaymentTimeout' labelKey='VenueForm:webBookingIncompletePaymentTimeout' placeholderKey='VenueForm:noExpiry' hintKey='VenueForm:webBookingIncompletePaymentTimeoutHint' value={webBookingIncompletePaymentTimeout} callback={val => this.setState({ webBookingIncompletePaymentTimeout: this.validateWebBookingIncompletePaymentTimeout(val) })} />
                <ct.Time id='webBookingMinTimeFromEventStart' labelKey='VenueForm:webBookingMinTimeFromEventStart' value={this.state.webBookingMinTimeFromEventStart} callback={val => this.setState({ webBookingMinTimeFromEventStart: this.validateWebBookingMinTimeFromEventStart(val ? val : Time.zero()) })} />

                <ct.Select id='culture' labelKey='VenueForm:culture' value={culture} options={cultures.sort((c1,c2) => stringComparer(c1.displayName, c2.displayName)).map(c => ({ key: c.name, name: c.displayName }))} callback={val => this.setState({ culture: this.validateCulture(val) })} />
                <ct.Select id='timeZone' labelKey='VenueForm:timeZone' value={({ ...timeZoneId, value: timeZoneId.value.toString() })} options={timeZones.map(z => ({ key: z.id.toString(), name: z.name }))} callback={val => this.setState({timeZoneId: this.validateTimeZone(parseInt(val))})} />
                <ct.Select id='currency' labelKey='VenueForm:currency' value={({ ...currency, value: currency.value.toString() })} options={currencies.map(c => ({ key: c.id.toString(), name: c.name }))} callback={val => this.setState({ currency: this.validateCurrency(parseInt(val)) })} />
                <ct.Select id='firstDayOfWeek' labelKey='VenueForm:firstDayOfWeek' value={({ ...firstDayOfWeek, value: firstDayOfWeek.value.toString() })} options={firstDayOfWeekOptions} callback={val => this.setState({ firstDayOfWeek: this.validateFirstDayOfWeek(parseInt(val)) })} />
                <ct.Select id='timeFormat' labelKey='VenueForm:timeFormat' value={({ ...timeFormat, value: timeFormat.value.toString() })} options={timeFormatOptions} callback={val => this.setState({ timeFormat: this.validateTimeFormat(parseInt(val)) })} />
                <ct.Select id='dateFormat' labelKey='VenueForm:dateFormat' value={({ ...dateFormat, value: dateFormat.value.toString() })} options={dateFormatOptions} callback={val => this.setState({ dateFormat: this.validateDateFormat(parseInt(val)) })} />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                <ct.NumberBox id='minAgeWarningThreshold' labelKey='VenueForm:minAgeWarningThreshold' placeholderKey='VenueForm:minAgeWarningThreshold' value={minAgeWarningThreshold} callback={val => this.setState({ minAgeWarningThreshold: this.validateMinAgeWarningThreshold(val) })} min='0' />
                <ct.NumberBox id='minAgeForSelfRegistration' labelKey='VenueForm:minAgeForSelfRegistration' placeholderKey='VenueForm:minAgeForSelfRegistration' value={minAgeForSelfRegistration} callback={val => this.setState({ minAgeForSelfRegistration: this.validateMinAgeForSelfRegistration(val) })} min='0' />
                <ct.NumberBox id='minAge' labelKey='VenueForm:minAge' placeholderKey='VenueForm:minAge' value={minAge} callback={val => this.setState({ minAge: this.validateMinAge(val) })} min='0' />
                <ct.Checkbox id='requireLatestTerms' labelKey='VenueForm:requireLatestTerms' value={requireLatestTerms} callback={val => this.setState({ requireLatestTerms: this.validateRequireLatestTerms(val) })} />

                <h4>{t('VenueForm:openingHours')}</h4>
                <div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Monday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.mondayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ mondayClosed: e.currentTarget.checked})}}  /></div>
                        </div>
                        <ct.Time id='mondayOpenTime' labelKey='' value={this.state.mondayOpenTime} callback={val => this.setState({ mondayOpenTime: this.validateMondayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.mondayClosed} style={timeStyle} />
                        <ct.Time id='mondayCloseTime' labelKey='' value={this.state.mondayCloseTime} callback={val => this.setState({ mondayCloseTime: this.validateMondayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.mondayClosed} style={timeStyle} />
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Tuesday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.tuesdayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ tuesdayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='tuesdayOpenTime' labelKey='' value={this.state.tuesdayOpenTime} callback={val => this.setState({ tuesdayOpenTime: this.validateTuesdayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.tuesdayClosed} style={timeStyle}/>
                        <ct.Time id='tuesdayCloseTime' labelKey='' value={this.state.tuesdayCloseTime} callback={val => this.setState({ tuesdayCloseTime: this.validateTuesdayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.tuesdayClosed} style={timeStyle} />
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Wednesday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.wednesdayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ wednesdayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='wednesdayOpenTime' labelKey='' value={this.state.wednesdayOpenTime} callback={val => this.setState({ wednesdayOpenTime: this.validateWednesdayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.wednesdayClosed} style={timeStyle} />
                        <ct.Time id='wednesdayCloseTime' labelKey='' value={this.state.wednesdayCloseTime} callback={val => this.setState({ wednesdayCloseTime: this.validateWednesdayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.wednesdayClosed} style={timeStyle}/>
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Thursday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.thursdayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ thursdayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='thursdayOpenTime' labelKey='' value={this.state.thursdayOpenTime} callback={val => this.setState({ thursdayOpenTime: this.validateThursdayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.thursdayClosed} style={timeStyle} />
                        <ct.Time id='thursdayCloseTime' labelKey='' value={this.state.thursdayCloseTime} callback={val => this.setState({ thursdayCloseTime: this.validateThursdayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.thursdayClosed} style={timeStyle} />
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Friday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.fridayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ fridayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='fridayOpenTime' labelKey='' value={this.state.fridayOpenTime} callback={val => this.setState({ fridayOpenTime: this.validateFridayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.fridayClosed} style={timeStyle} />
                        <ct.Time id='fridayCloseTime' labelKey='' value={this.state.fridayCloseTime} callback={val => this.setState({ fridayCloseTime: this.validateFridayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.fridayClosed} style={timeStyle} />
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Saturday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.saturdayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ saturdayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='saturdayOpenTime' labelKey='' value={this.state.saturdayOpenTime} callback={val => this.setState({ saturdayOpenTime: this.validateSaturdayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.saturdayClosed} style={timeStyle}/>
                        <ct.Time id='saturdayCloseTime' labelKey='' value={this.state.saturdayCloseTime} callback={val => this.setState({ saturdayCloseTime: this.validateSaturdayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.saturdayClosed} style={timeStyle} />
                    </div>
                    <div style={({ display: 'inline-block' })}>
                        <div>
                            <label className='time-label' style={({ width: '100%' })}>{t('Global:Sunday')}</label>
                            <div style={({ margin: '0 10px 5px 10px' })}>{t('Global:Closed')}  <input id='mondayOpenClosed' type='checkbox' checked={this.state.sundayClosed} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ sundayClosed: e.currentTarget.checked }) }} /></div>
                        </div>
                        <ct.Time id='sundayOpenTime' labelKey='' value={this.state.sundayOpenTime} callback={val => this.setState({ sundayOpenTime: this.validateSundayOpenTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.sundayClosed} style={timeStyle}/>
                        <ct.Time id='sundayCloseTime' labelKey='' value={this.state.sundayCloseTime} callback={val => this.setState({ sundayCloseTime: this.validateSundayCloseTime(val ? val : Time.zero()) })} timeFormat={timeFormat.value} disabled={this.state.sundayClosed} style={timeStyle} />
                    </div>
                </div>

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }
};



const matStateToProps = (state: ApplicationState) => ({
    saveComplete: state.venues.saveComplete,
    saveError: state.venues.saveError,
    validationErrors: state.venues.validationErrors,
    cultures: state.cultures.cultures,
    isLoadingCultures: state.cultures.isLoading,
    currencies: state.currencies.currencies,
    isLoadingCurrencies: state.currencies.isLoading,
    timeZones: state.timeZones.timeZones,
    isLoadingTimeZones: state.timeZones.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveVenue: bindActionCreators(VenueActions.actionCreators.saveVenue, dispatch),
    loadCultures: bindActionCreators(CultureActions.actionCreators.loadCultures, dispatch),
    loadCurrencies: bindActionCreators(CurrencyActions.actionCreators.loadCurrencies, dispatch),
    loadTimeZones: bindActionCreators(TimeZoneActions.actionCreators.loadTimeZones, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(VenueForm);
