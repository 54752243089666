
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { isNullOrEmpty, clickHandler, range } from '../../../utils/util';
import { allCountries } from '../../../store/global/countries';
import { Gender, MarketingPreference } from '../../../store/pages/customer/types';
import TagSelection from '../../global/tagSelection';
import { SpecialTagType, Tag } from '../../../store/pages/tags/types';
import { ImageFile } from '../../../utils/images';
import Dropzone from 'react-dropzone';

export interface CustomerDetails {
    customerId: string;
    key: string;
    companyName: string;
    firstname: string;
    lastname: string;
    nickname: string | null;
    emailAddress: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    town: string;
    county: string;
    countryId: number;
    postalCode: string;
    isOrganiser: boolean;
    gender: Gender;
    marketingPreference: MarketingPreference;
    resultsPreference: MarketingPreference;
    publicResultsConsent: boolean;
    emergencyContactName: string;
    emergencyContactNumber: string;
    birthYear?: number;
    birthMonth?: number;
    birthDay?: number;
    hasActiveMembership: boolean;
    tags: Tag[];
    notes: string;
}

interface EventCustomerFormProps {
    customer: CustomerDetails;
    add: boolean;
    requirePhoneNumber: boolean;
    showIsOrganiser: boolean;
    defaultCountryId: number;
    canChangeDob: boolean;
    canAddPhoto: boolean;
    addCustomer: (booking: CustomerDetails) => void;
    updateCustomer: (booking: CustomerDetails, photoImg: File | null) => void;
    cancel: () => void;
}

interface EventCustomerFormState {
    companyName: ct.FormValue<string>;
    firstName: ct.FormValue<string>;
    lastName: ct.FormValue<string>;
    emailAddress: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
    addressLine1: ct.FormValue<string>;
    addressLine2: ct.FormValue<string>;
    addressLine3: ct.FormValue<string>;
    addressLine4: ct.FormValue<string>;
    town: ct.FormValue<string>;
    county: ct.FormValue<string>;
    countryId: ct.FormValue<number>;
    postalCode: ct.FormValue<string>;
    isOrganiser: ct.FormValue<boolean>;
    emailMarketingAgreed: ct.FormValue<boolean>;
    resultsEmailAgreed: ct.FormValue<boolean>;
    publicResultsConsent: ct.FormValue<boolean>;
    errorMessage: string;
    emergencyContactName: ct.FormValue<string>;
    emergencyContactNumber: ct.FormValue<string>;
    birthYear: ct.FormValue<number>;
    birthMonth: ct.FormValue<number>;
    birthDay: ct.FormValue<number>;
    gender: ct.FormValue<Gender>;
    tags: Tag[];
    notes: ct.FormValue<string>;
    photoImageId: string | null;
    photoImage: ImageFile | null;
}

class EventCustomerForm extends React.Component<EventCustomerFormProps, EventCustomerFormState> {

    constructor(props: EventCustomerFormProps) {
        super(props);

        this.state = this.buildStateFromProps(props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps = (props: EventCustomerFormProps): EventCustomerFormState => {
        const { customerId, companyName, firstname, lastname, emailAddress, phoneNumber, isOrganiser, addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, marketingPreference, resultsPreference, publicResultsConsent, tags, emergencyContactName, emergencyContactNumber, notes, birthYear, birthMonth, birthDay, gender } = props.customer;
        const state = this.validateContactDetails(customerId, companyName, firstname, lastname, emailAddress, phoneNumber, isOrganiser, addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, (marketingPreference & MarketingPreference.Email) === MarketingPreference.Email, (resultsPreference & MarketingPreference.Email) === MarketingPreference.Email, publicResultsConsent, tags, notes ? notes : '');
        const dobState = this.validateDob(birthYear || 0, birthMonth || 0, birthDay || 0);
        return {
            ...state,
            emergencyContactName: ct.asFormValue('emergencyContactName', emergencyContactName),
            emergencyContactNumber: ct.asFormValue('emergencyContactNumber', emergencyContactNumber),
            birthYear: dobState.birthYear,
            birthMonth: dobState.birthMonth,
            birthDay: dobState.birthDay,
            gender: this.validateGender(gender),
            photoImage: null,
            photoImageId: null
        }
    }

    componentDidUpdate(oldProps: EventCustomerFormProps) {

        if (this.props.customer.key !== oldProps.customer.key) {
            this.setState((prevState, props) => this.buildStateFromProps(props));
        }
    }

    setContactDetails = (companyName: string, firstName: string, lastName: string, emailAddress: string, phoneNumber: string, isOrganiser: boolean,
        addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, town: string, county: string, countryId: number,
        postalCode: string, emailMarketingAgreed: boolean, resultsEmailAgreed: boolean, publicResultsConsent: boolean, tags: Tag[], notes: string) => {
        this.setState((prevProps, props) => this.validateContactDetails(props.customer.customerId, companyName, firstName, lastName, emailAddress, phoneNumber, isOrganiser, addressLine1, addressLine2, addressLine3, addressLine4, town, county, countryId, postalCode, emailMarketingAgreed, resultsEmailAgreed, publicResultsConsent, tags, notes));
    }

    validateContactDetails = (customerId: string, companyName: string, firstName: string, lastName: string, emailAddress: string, phoneNumber: string, isOrganiser: boolean,
                              addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, town: string, county: string, countryId: number,
        postalCode: string, emailMarketingAgreed: boolean, resultsEmailAgreed: boolean, publicResultsConsent: boolean, tags: Tag[], notes: string) => {

        const {  requirePhoneNumber } = this.props;

        const required = !isNullOrEmpty(customerId) || !isNullOrEmpty(firstName) || !isNullOrEmpty(lastName) || !isNullOrEmpty(emailAddress) || !isNullOrEmpty(phoneNumber)
            || !isNullOrEmpty(addressLine1) || !isNullOrEmpty(addressLine2) || !isNullOrEmpty(addressLine3) || !isNullOrEmpty(addressLine4) || !isNullOrEmpty(town) || !isNullOrEmpty(county) || !isNullOrEmpty(postalCode);

        const addressRequired = !isNullOrEmpty(addressLine1) || !isNullOrEmpty(addressLine2) || !isNullOrEmpty(addressLine3) || !isNullOrEmpty(addressLine4) || !isNullOrEmpty(town) || !isNullOrEmpty(county) || !isNullOrEmpty(postalCode);

        // Default the country to the venue's country if not set
        if (countryId === 0) {
            countryId = this.props.defaultCountryId;
        }

        return {
            companyName: this.validateContactCompanyName(companyName),
            firstName: this.validateContactFirstName(firstName, required),
            lastName: this.validateContactLastName(lastName, required),
            emailAddress: this.validateContactEmailAddress(emailAddress, required),
            phoneNumber: this.validateContactPhoneNumber(phoneNumber, requirePhoneNumber && required),
            isOrganiser: this.validateIsOrganiser(isOrganiser),
            addressLine1: this.validateAddressLine1(addressLine1, addressRequired),
            addressLine2: this.validateAddressLine2(addressLine2, false),
            addressLine3: this.validateAddressLine3(addressLine3, false),
            addressLine4: this.validateAddressLine4(addressLine4, false),
            town: this.validateTown(town, false),
            county: this.validateCounty(county, false),
            countryId: this.validateCountryId(countryId, addressRequired),
            postalCode: this.validatePostalCode(postalCode, addressRequired),
            emailMarketingAgreed: this.validateEmailMarketingPreference(emailMarketingAgreed),
            resultsEmailAgreed: this.validateResultsEmailPreference(resultsEmailAgreed),
            publicResultsConsent: ct.asFormValue('publicResultsConsent', publicResultsConsent),
            tags: tags,
            notes: ct.asFormValue('notes', notes),
            errorMessage: ''
        };
    }

    setDob = (birthYear: number, birthMonth: number, birthDay: number) => this.setState(this.validateDob(birthYear, birthMonth, birthDay)) 

    validateDob = (birthYear: number, birthMonth: number, birthDay: number) => {
        const { canChangeDob } = this.props;

        if (!canChangeDob || (birthYear === 0 && birthMonth === 0 && birthDay === 0)) {
            return { birthYear: ct.asFormValue('birthYear', birthYear), birthMonth: ct.asFormValue('birthMonth', birthMonth), birthDay: ct.asFormValue('birthDay', birthDay)  }
        }

        const today = new Date();
        return {
            birthYear: ct.asFormValue('birthYear', birthYear, birthYear >= today.getFullYear() - 100 && birthYear <= today.getFullYear(), true),
            birthMonth: ct.asFormValue('birthMonth', birthMonth, birthMonth >= 1 && birthMonth <= 12, true),
            birthDay: ct.asFormValue('birthDay', birthDay, new Date(birthYear, birthMonth - 1, birthDay).getDate() === birthDay, true)
        }
    }

    validateContactCompanyName = (val: string) => v.validate(val, 'companyName', [], []);
    validateContactFirstName = (val: string, isRequired: boolean) => v.validate(val, 'firstName', isRequired ? [v.required] : [], []);
    validateContactLastName = (val: string, isRequired: boolean) => v.validate(val, 'lastName', isRequired ? [v.required] : [], []);
    validateContactEmailAddress = (val: string, isRequired: boolean) => v.validate(val, 'emailAddress', isRequired ? [v.required] : [], []);
    validateContactPhoneNumber = (val: string, isRequired: boolean) => v.validate(val, 'phoneNumber', isRequired ? [v.required] : [], []);

    validateAddressLine1 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine1', isRequired ? [v.required] : [], []);
    validateAddressLine2 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine2', isRequired ? [v.required] : [], []);
    validateAddressLine3 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine3', isRequired ? [v.required] : [], []);
    validateAddressLine4 = (val: string, isRequired: boolean) => v.validate(val, 'addressLine4', isRequired ? [v.required] : [], []);
    validateTown = (val: string, isRequired: boolean) => v.validate(val, 'town', isRequired ? [v.required] : [], []);
    validateCounty = (val: string, isRequired: boolean) => v.validate(val, 'county', isRequired ? [v.required] : [], []);
    validateCountryId = (val: number, isRequired: boolean) => v.validate(val, 'countryId', isRequired ? [v.numeric, v.required] : [], []);
    validatePostalCode = (val: string, isRequired: boolean) => v.validate(val, 'postalCode', isRequired ? [v.required] : [], []);
    validateIsOrganiser = (val: boolean) => v.validate(val, 'isOrganiser', [], []);
    validateEmailMarketingPreference = (val: boolean) => v.validate(val, 'emailMarketingPreference', [], []);
    validateResultsEmailPreference = (val: boolean) => v.validate(val, 'resultsEmailPreference', [], []);
    validateGender = (val: Gender) => v.validate(val, 'gender', [], []);

    buildContactFromState = (state: EventCustomerFormState): CustomerDetails => {
        const { customer, canChangeDob } = this.props;
        return {
            ...customer,
            companyName: state.companyName.value,
            firstname: state.firstName.value,
            lastname: state.lastName.value,
            emailAddress: state.emailAddress.value,
            phoneNumber: state.phoneNumber.value,
            isOrganiser: state.isOrganiser.value,
            addressLine1: state.addressLine1.value,
            addressLine2: state.addressLine2.value,
            addressLine3: state.addressLine3.value,
            addressLine4: state.addressLine4.value,
            town: state.town.value,
            county: state.county.value,
            countryId: state.countryId.value,
            postalCode: state.postalCode.value,
            marketingPreference: (state.emailMarketingAgreed.value ? MarketingPreference.Email : MarketingPreference.None),
            resultsPreference: (state.resultsEmailAgreed.value ? MarketingPreference.Email : MarketingPreference.None),
            emergencyContactName: state.emergencyContactName.value,
            emergencyContactNumber: state.emergencyContactNumber.value,
            publicResultsConsent: state.publicResultsConsent.value,
            birthYear: canChangeDob ? state.birthYear.value : undefined,
            birthMonth: canChangeDob ? state.birthMonth.value : undefined,
            birthDay: canChangeDob ? state.birthDay.value : undefined,
            gender: canChangeDob ? state.gender.value : Gender.NotProvided,
            tags: state.tags,
            notes: state.notes.value
        }
    }

    validate = () => {
        if (!v.isValid(this.state)) {
            this.setState({ errorMessage: 'EventCustomers:eventNameValidationsError' });
            return false;
        } else if (isNullOrEmpty(this.state.firstName.value) || isNullOrEmpty(this.state.lastName.value) || isNullOrEmpty(this.state.emailAddress.value)) {
            this.setState({ errorMessage: 'EventCustomerForm:enterDetails' });
            return false;
        }

        return true;
    }

    addCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (this.validate()) {
            this.props.addCustomer(this.buildContactFromState(this.state));
        }
    }

    editCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (this.validate()) {
            this.props.updateCustomer(this.buildContactFromState(this.state), this.state.photoImage ? this.state.photoImage.file : null);
        }
    }

    onPhotoImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ photoImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    render() {

        const { showIsOrganiser, canChangeDob, canAddPhoto } = this.props;
        const { errorMessage, companyName, firstName, lastName, emailAddress, phoneNumber, isOrganiser, addressLine1, addressLine2, addressLine3, addressLine4, town, county,
            countryId, postalCode, emailMarketingAgreed, resultsEmailAgreed, publicResultsConsent, tags, emergencyContactNumber, emergencyContactName, notes, birthYear, birthMonth, birthDay, gender } = this.state;
        const { t } = this.context;

        const actionButton = this.props.add
            ? <button className='btn btn-success' onClick={this.addCustomer}>{t('EventCustomers:addCustomer')}</button>
            : <button className='btn btn-success' onClick={this.editCustomer}>{t('Global:update')}</button>;

        let photoPreviewImg: JSX.Element | null = null;
        const photoImageUrl = this.state.photoImage ? this.state.photoImage.preview : null;
        if (photoImageUrl) {
            photoPreviewImg = <img src={photoImageUrl} className='file-preview' alt='light logo'></img>;
        }

        return (
            <div>
                <form className='data-form' autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <ct.TextBox id='contactFirstName' labelKey='Global:firstName' placeholderKey='Global:firstName' value={firstName} callback={val => this.setContactDetails(companyName.value, val, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                </div>
                                <div className='col-xs-12'>
                                    <ct.TextBox id='contactLastName' labelKey='Global:lastName' placeholderKey='Global:lastName' value={lastName} callback={val => this.setContactDetails(companyName.value, firstName.value, val, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                </div>
                                <div className='col-xs-12'>
                                    <ct.Email id='contactEmailAddress' labelKey='Global:emailAddress' placeholderKey='Global:emailAddress' value={emailAddress} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, val, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                </div>
                                <div className='col-xs-12'>
                                    <ct.PhoneNumber id='contactPhoneNumber' labelKey='Global:phoneNumber' placeholderKey='Global:phoneNumber' value={phoneNumber} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, val, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                </div>
                                <div className='col-xs-12'>
                                    <ct.TextBox id='contactCompanyName' labelKey='Global:companyName' placeholderKey='Global:companyName' value={companyName} callback={val => this.setContactDetails(val, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                </div>
                                {canChangeDob ? this.renderDob(birthYear, birthMonth, birthDay) : null }
                                {canChangeDob ? this.renderGender(gender) : null}

                                <div className='col-xs-12'>
                                    {showIsOrganiser ? <ct.Checkbox id='isOrganiser' labelKey='EventCustomers:isOrganiser' value={isOrganiser} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, val, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} /> : null}
                                    <ct.Checkbox id='emailMarketingAgreed' labelKey='EventCustomers:emailMarketing' value={emailMarketingAgreed} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, val, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                                    <ct.Checkbox id='resultsEmailAgreed' labelKey='EventCustomers:emailResults' value={resultsEmailAgreed} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, val, publicResultsConsent.value, tags, notes.value)} />
                                    <ct.Checkbox id='publicResultsConsent' labelKey='Global:publicResultsConsent' value={publicResultsConsent} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, val, tags, notes.value)} />
                                </div>
                                <div className='col-xs-12'>
                                    <ct.TextArea id='notes' labelKey='Global:notes' rows={4} value={notes} noMaxWidth={true} callback={n => this.setState({ notes: ct.asFormValue('notes', n) })} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <label>{t('Global:tags')}</label>
                                    <TagSelection selectedTags={tags} canCreateTag={true} specialTagType={SpecialTagType.None} tagAdded={(tag: Tag) => this.setState(s => ({ tags: s.tags.concat(tag) }))} removeTag={(tagId: string) => this.setState(s => ({ tags: s.tags.filter(t => t.id !== tagId) }))} />
                                </div>
                            </div>
                            {canAddPhoto ? <div className='row'>
                                <div className='col-xs-12'>
                                    <span>{t('EventCustomerForm:customerPhoto')}</span>
                                    <div>
                                        <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onPhotoImageDrop}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()} className='file-drop'>
                                                    <input {...getInputProps()} />
                                                    <p>{t('Global:imageDropText')}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {photoPreviewImg}
                                    </div>

                                </div>
                            </div> : null}
                        </div>
                    </div>
                    <div className='row separator mt-15'>
                        <div className='col-xs-12'>
                            <ct.TextBox id='addressLine1' labelKey='Global:addressLine1' placeholderKey='Global:addressLine1' value={addressLine1} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, val, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='addressLine2' labelKey='Global:addressLine2' placeholderKey='Global:addressLine2' value={addressLine2} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, val, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='addressLine3' labelKey='Global:addressLine3' placeholderKey='Global:addressLine3' value={addressLine3} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, val, addressLine4.value, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='addressLine4' labelKey='Global:addressLine4' placeholderKey='Global:addressLine4' value={addressLine4} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, val, town.value, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='town' labelKey='Global:town' placeholderKey='Global:town' value={town} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, val, county.value, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='county' labelKey='Global:county' placeholderKey='Global:county' value={county} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, val, countryId.value, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.Country id='country' labelKey='Global:country' placeholderKey='Global:country' value={countryId} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, val, postalCode.value, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} countries={allCountries} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='postalCode' labelKey='Global:postalCode' placeholderKey='Global:postalCode' value={postalCode} callback={val => this.setContactDetails(companyName.value, firstName.value, lastName.value, emailAddress.value, phoneNumber.value, isOrganiser.value, addressLine1.value, addressLine2.value, addressLine3.value, addressLine4.value, town.value, county.value, countryId.value, val, emailMarketingAgreed.value, resultsEmailAgreed.value, publicResultsConsent.value, tags, notes.value)} />
                        </div>

                        <div className='col-xs-12'>
                            <ct.TextBox id='emergencyContactName' labelKey='Global:emergencyContactName' placeholderKey='Global:emergencyContactName' value={emergencyContactName} callback={val => this.setState({ emergencyContactName: ct.asFormValue('emergencyContactName', val) })} />
                        </div>
                        <div className='col-xs-12'>
                            <ct.TextBox id='emergencyContactNumber' labelKey='Global:emergencyContactNumber' placeholderKey='Global:emergencyContactNumber' value={emergencyContactNumber} callback={val => this.setState({ emergencyContactNumber: ct.asFormValue('emergencyContactNumber', val)})} />
                        </div>
                    </div>

                    {isNullOrEmpty(errorMessage) ? null : <div className='alert alert-danger'>{t(errorMessage)}</div>}

                    <div className='btn-toolbar'>
                        {actionButton}
                        <button className='btn btn-basic' onClick={e => clickHandler(e, this.props.cancel)}>{this.context.t('Global:cancel')}</button>
                    </div>
                </form>
            </div>
        );
    }

    renderDob = (birthYear: ct.FormValue<number>, birthMonth: ct.FormValue<number>, birthDay: ct.FormValue<number>) => {
        const { t } = this.context;
        const dayOptions = range(1, 31).map(d => ({ key: d.toString(), name: d.toString() }))
        const monthOptions = [t('Global:January'), t('Global:February'), t('Global:March'), t('Global:April'), t('Global:May'), t('Global:June'), t('Global:July'), t('Global:August'), t('Global:September'), t('Global:October'), t('Global:November'), t('Global:December')].map((m, ix) => ({ key: (ix + 1).toString(), name: m }))

        const today = new Date();
        const yearOptions = range(today.getFullYear() - 100, today.getFullYear()).reverse().map(y => ({ key: y.toString(), name: y.toString() }))
        const baseStyle = { display: 'inline-block' }
        return <div className='col-xs-12'>
            <label>{t('Global:birthday')}</label>
            <div>
                <ct.Select id='birthDay' labelKey='' style={{ ...baseStyle, width: '90px' }} value={{ ...birthDay, value: birthDay.value.toString() }} callback={val => this.setDob(birthYear.value, birthMonth.value, parseInt(val))} options={dayOptions} />
                <ct.Select id='birthMonth' labelKey='' style={{ ...baseStyle, width: '130px' }} value={{ ...birthMonth, value: birthMonth.value.toString() }} callback={val => this.setDob(birthYear.value, parseInt(val), birthDay.value)} options={monthOptions} />
                <ct.Select id='birthYear' labelKey='' style={{ ...baseStyle, width: '100px' }} value={{ ...birthYear, value: birthYear.value.toString() }} callback={val => this.setDob(parseInt(val), birthMonth.value, birthDay.value)} options={yearOptions} />
            </div>
        </div>
    }

    renderGender = (gender: ct.FormValue<Gender>) => {
        const { t } = this.context;

        const options = Object.keys(Gender).filter(k => typeof Gender[k as any] === 'number').map(k => ({ key: Gender[k as any].toString(), name: t(`Gender:${k}`) }));
        return <div className='col-xs-12'>
                <ct.Select id='gender' labelKey='EventCustomerForm:gender' value={({ ...gender, value: gender.value.toString() })} callback={val => this.setState({ gender: this.validateGender(parseInt(val)) })} options={options} />
            </div>
    }
}

export default EventCustomerForm;